export const lessons = [
    { label: 'IPA', code: 'subject_ipa' },
    { label: 'IPS', code: 'subject_ips' },
    { label: 'Bahasa Indonesia', code: 'subject_indonesia' },
    { label: 'Bahasa Inggris', code: 'subject_inggris' },
    { label: 'Matematika', code: 'subject_matematika' },
    { label: 'PKN', code: 'subject_ppkn' },
    { label: 'Informatika', code: 'subject_informatika' },
    { label: 'PJOK', code: 'subject_pjok' },
    { label: 'Agama Islam', code: 'subject_agama_islam' },
    { label: 'Agama Kristen', code: 'subject_agama_kristen' },
    { label: 'Agama Katolik', code: 'subject_agama_katolik' },
    { label: 'Agama Hindu', code: 'subject_agama_hindu' },
    { label: 'Agama Buddha', code: 'subject_agama_budha' },
    { label: 'Agama Khonghucu', code: 'subject_agama_khonghucu' },
    { label: 'Kepercayaan', code: 'subject_kepercayaan' },
    { label: 'Seni Tari', code: 'subject_seni_tari' },
    { label: 'Seni Musik', code: 'subject_seni_musik' },
    { label: 'Seni Rupa', code: 'subject_seni_rupa' },
    { label: 'Seni Teater', code: 'subject_seni_teater' },
    { label: 'Sosiologi', code: 'subject_sosiologi' },
    { label: 'Antropologi', code: 'subject_antropologi' },
    { label: 'Ekonomi', code: 'subject_ekonomi' },
    { label: 'Geografi', code: 'subject_geografi' },
    { label: 'Sejarah', code: 'subject_sejarah' },
    { label: 'Prakarya', code: 'subject_prakarya' },
    { label: 'IPAS', code: 'subject_ipas' },
    { label: 'Teknik Konstruksi dan Perumahan', code: 'subject_teknik_konstruksi_dan_perumahan' },
    { label: 'Teknik Otomotif', code: 'subject_teknik_otomotif' },
    { label: 'Teknik Elektronika', code: 'subject_teknik_elektronika' },
    { label: 'Teknik Pesawat Udara', code: 'subject_teknik_pesawat_udara' },
    { label: 'Teknik Konstruksi Kapal', code: 'subject_teknik_konstruksi_kapal' },
    { label: 'Teknik Ketenagalistrikan', code: 'subject_teknik_ketenagalistrikan' },
    { label: 'Teknik Geospasial', code: 'subject_teknik_geospasial' },
    { label: 'Teknik Geologi Pertambangan', code: 'subject_teknik_geologi_pertambangan' },
    { label: 'Layanan Kesehatan', code: 'subject_layanan_kesehatan' },
    { label: 'Agriteknologi Pengolahan Hasil Pertanian', code: 'subject_agriteknologi_pengolahan_hasil_pertanian' },
    { label: 'Manajemen Perkantoran dan Layanan Bisnis', code: 'subject_manajemen_perkantoran_dan_layanan_bisnis' },
    { label: 'Usaha Layanan Pariwisata', code: 'subject_usaha_layanan_pariwisata' },
    { label: 'Desain Komunikasi Visual', code: 'subject_desain_komunikasi_visual' },
    { label: 'Teknik Furniture', code: 'subject_teknik_furniture' },
    { label: 'Kuliner', code: 'subject_kuliner' },
    { label: 'Biologi', code: 'subject_biologi' },
    { label: 'Fisika', code: 'subject_fisika' },
    { label: 'Kimia', code: 'subject_kimia' }
];

export const classLevel = [
    { label: 'I', code: 'class_1' },
    { label: 'VII', code: 'class_7' },
    { label: 'II', code: 'class_2' },
    { label: 'VIII', code: 'class_8' },
    { label: 'III', code: 'class_3' },
    { label: 'IX', code: 'class_9' },
    { label: 'IV', code: 'class_4' },
    { label: 'X', code: 'class_10' },
    { label: 'V', code: 'class_5' },
    { label: 'XI', code: 'class_11' },
    { label: 'VI', code: 'class_6' },
    { label: 'XII', code: 'class_12' }
];